import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Image from '../../../components/Image';

const VirajPandey = () => (
  <Layout
    title="Alumni Spotlight - Viraj Pandey"
    className="alumni-center bios"
  >
    {/* Alumni Profile */}
    <Section className="alumni">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../overview">Overview</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../alumni-spotlight">Alumni Spotlight</Link>
          </li>
          <li className="breadcrumb active">Viraj Pandey</li>
        </ul>
        <Row>
          <Column size={7} offset={1} className="alumni__profile-left pr-2">
            <h2 className="alumni__profile-greeting">Meet Alumni:</h2>
            <h1 className="alumni__profile-title">Viraj Pandey</h1>
            <h3 className="alumni__profile-type">
              2021 Young Scientist Challenge Winner
            </h3>
            <h4 className="alumni__profile-project">
              Now in 10th grade, Viraj is working on a new project aimed at
              re-inventing farming strategies. Viraj enjoys science, history and
              3D modeling, and is in his school’s gardening club.
            </h4>
            <h2 className="alumni__profile-question">
              We know that you are working on a new project now. Tell us more
              about that! What have you been up to since participating in the 3M
              Young Scientist Challenge?
            </h2>
            <p>
              My project investigates the use of non-toxic, water-soluble, and
              biocompatible Graphene Quantum Dots (GQDs) in enhancing plant
              growth due to the following:
              <ul>
                <li>
                  Better oxygen supply and glucose generation in the plant
                </li>
                <li>
                  Enabling photomorphogenesis by augmenting the
                  Photosynthetically Active Radiation (PAR)
                </li>
                <li>Enabling early plant disease detection</li>
              </ul>
            </p>
            <h2 className="alumni__profile-question">
              What are your favorite three school subjects?
            </h2>
            <p>Science, History, and 3D-Modeling</p>
            <h2 className="alumni__profile-question">
              Are you involved in any school teams or clubs, if so which ones?
            </h2>
            <p>I am a member of my school’s Makers Club and Gardening club.</p>
            <h2 className="alumni__profile-question">
              What are your plans for the future?
            </h2>
            <p>
              I want to major in a STEM field and would love to be in the
              research field. At this point, I don't know exactly what kind of
              science I would like to major in, but it could be in chemistry,
              physics, material sciences, or astrophysics.
            </p>
            <h2 className="alumni__profile-question">
              Currently, what about the 3M YSC Alumni Network most excites you?
            </h2>
            <p>
              Connecting with past years' 3M winners and no matter when you did
              the challenge is the best thing ever! We all are being given
              another chance to work under one platform and continue our ideas
              or bring about new ideas. I would love to hear about their
              projects! I was super excited to see someone from the year 2011 in
              the team of finalists! Great ideas will be exchanged again.
            </p>
          </Column>
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="global__profile-image">
                <Image filename="Pandey-Viraj-2021-Finalist-Quote.png" />
              </div>
              <div className="global__profile-content">
                <h4 className="quote">
                  “Being a 3M YSC finalist gave me a platform to express my idea
                  on a huge and esteemed platform where I was heard and rewarded
                  for it.”
                </h4>
                <h3>Viraj Pandey</h3>
                <p>2021 Finalist</p>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Related Alumni Profiles */}
    <Section className="related-profiles">
      <Container>
        <Row>
          <Column fullwidth>
            <h2>Related Alumni</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Katie-Lampo-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2017 Finalist</span>
                <h3>Kathryn Lampo</h3>
                <p>
                  Kathryn is currently a sophomore at Columbia University
                  pursuing a B.S. in Mechanical Engineering with a minor in
                  Applied Physics. She’s also interested in aerospace
                  engineering!
                </p>
                <Link to="../katie-lampo" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Kaien-Yang-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2016 Finalist</span>
                <h3>Kaien Yang</h3>
                <p>
                  Kaien is a sophomore at Standford University majoring in
                  Computer Science with an AI track. He works with robots and
                  machine learning, and plays for Stanford’s club tennis team.
                </p>
                <Link to="../kaien-yang" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Nicholas-LaJoie-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2009 Finalist</span>
                <h3>Nicholas LaJoie</h3>
                <p>
                  Nicholas studied Computer Engineering in college and is
                  currently pursuing a career in technology. He plays the drums
                  and has worked in filmmaking.
                </p>
                <Link to="../nicholas-lajoie" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default VirajPandey;
